// libraries import
import {useQuery, useMutation, queryClient} from "../hooks/react-query"
import {getLastNDaysTrackByIdentity, requestData} from "../lib/ct-api"
import {delay} from "../utils/delay"

import {getWorkspace} from "./auth"

const CT_TRACK_QUERY_KEY = ["ct-track"]

/**
 * @param {string} identify
 * @param {import('../hooks/react-query').UseQueryOptions<LastNDayContactTracks, FetchError} config
 */
export function useLastNDaysTracks(identify, config = {}) {
  return useQuery({
    queryKey: [...CT_TRACK_QUERY_KEY, identify],
    queryFn: () => {
      return getLastNDaysTrackByIdentity({
        workspace: getWorkspace(),
        identify,
        days: 14,
      })
    },
    ...config,
  })
}

export function removeCachedTracks() {
  queryClient.removeQueries(CT_TRACK_QUERY_KEY)
}

/**
 * @param {RequestDataParams}
 */
const requestDataWithReload = async ({identifies}) => {
  const result = await requestData({identifies})
  await delay(5000)
  identifies.forEach(identify => {
    queryClient.invalidateQueries([...CT_TRACK_QUERY_KEY, identify], {
      exact: true,
    })
  })
  return result
}

/**
 * @template TResult,  RequestDataParams, TContext
 * @param {import('../hooks/react-query').UseMutationOptions<TResult, FetchError, RequestDataParams, TContext} config
 */
export function useRequestData(config = {}) {
  return useMutation(requestDataWithReload, {
    ...config,
    errorMessage: "There is an error occurred, please try again later.",
    // onSettled: (_result, _error, variables) => {
    //   // invalidate queries after a while
    //   setTimeout(() => {
    //     variables.identifies.forEach(identify => {
    //       queryCache.invalidateQueries(["ct-track", identify], {
    //         exact: true,
    //       })
    //     })
    //   }, 5000)

    //   onSettled && onSettled(_result, _error, variables)
    // },
  })
}

/**
 * @typedef {import('../domains/ct-contact').ContactEntriesByDay} ContactEntriesByDay
 * @typedef {import('../domains/ct-contact').LastNDayContactTracks} LastNDayContactTracks
 * @typedef {import('../lib/ct-api/').RequestDataParams} RequestDataParams
 * @typedef {import('../lib/fetch').FetchError} FetchError
 */
