import * as React from "react"
import {navigate} from "gatsby"

import {IconButton} from "../components/base/icon-button"
import {Spacing} from "../components/base/spacing"
import ContentPlaceholder from "../components/base/content-placeholder"
import SEO from "../layouts/seo"

import ContactDetailTimeline from "../containers/contact-detail-timeline"
import RequestDataButton from "../containers/request-contact-data-button"

// services import
import {removeCachedTracks} from "../services/ct"
import {useUserStatus, navigateToCT} from "../services/user"

import "./app-contact-detail.scss"

/**
 * @type {React.FunctionComponent<PageProps>}
 * @param {PageProps} props
 */
const ContactDetailPage = props => {
  const searchParams = new URLSearchParams(props.location.search)
  const identity = searchParams.get("identity") || searchParams.get("identify")

  const {data: userStatus, isLoading} = useUserStatus({user: identity})

  const backToEmployee = () => {
    navigate("/app/employee?tab=roster-management")
  }

  React.useEffect(() => {
    // identity is not set , go to employee
    if (!identity) {
      backToEmployee()
    }
  }, [identity])

  // remove all the cached tracks when leave this page
  React.useEffect(() => {
    return () => {
      removeCachedTracks()
    }
  }, [])

  return (
    <div className="dashboard-page-container app-contact-detail-page-container">
      <SEO title="Contact Tracing Detail" />
      {/* page breadcrumbs  */}
      <div className="app-contact-detail-page-breadcrumbs row">
        <IconButton
          icon="btn-employee"
          aria-label="back to employee page"
          size={30}
          onClick={backToEmployee}
        />
        <Spacing spacing="l" />
        <IconButton
          icon="btn-back"
          aria-label="back to employee page"
          size={30}
          onClick={backToEmployee}
        />
      </div>

      <div className="app-contact-detail-page-content">
        {/* user name , request data button */}
        <h1 className="row name">
          <div className="row">
            <div className="indicator" />
            <span className="Sub-Heading-3">
              {isLoading ? (
                <ContentPlaceholder
                  className="text-loader-animation"
                  width={80}
                />
              ) : (
                userStatus?.userInfo?.name
              )}
            </span>
            <Spacing spacing="xl" />
            <span className="Body-Text-5">{identity}</span>
          </div>
          <RequestDataButton identity={identity} />
        </h1>

        {/* main contact data */}
        <div className="app-contact-detail-data-container">
          <ContactDetailTimeline
            identity={identity}
            onContactSelect={navigateToCT}
          />
        </div>
      </div>
    </div>
  )
}

export default ContactDetailPage

/**
 * @typedef {object} LocationState
 * @property {string=} name
 * @property {string=} id
 */
/**
 * @typedef {import('gatsby').PageProps<object, object, LocationState>} PageProps
 */
