import * as React from "react"

import {Icon} from "../components/base/icon"
import DefaultPlaceholder from "../components/default-placeholder"
import {noop} from "../utils/noop"

import {isUserCovid19Positive} from "../domains/user"
import {useLastNDaysTracks} from "../services/ct"

import "./contact-detail-timeline.scss"

const timeFormatter = new Intl.DateTimeFormat("en", {
  hour: "2-digit",
  minute: "2-digit",
  hourCycle: "h23",
})

const ContactDetailTimeline = ({identity, onContactSelect}) => {
  const {isLoading, data, error} = useLastNDaysTracks(identity)
  const {contactEntriesDays, usersStatus} = data || {}

  if (isLoading) {
    return (
      <div className="placeholder-container column-center">
        Loading, please wait...
      </div>
    )
  }

  return (
    <DefaultPlaceholder error={error} data={contactEntriesDays}>
      {(contactEntriesDays || []).map(contactEntriesByDay => {
        return (
          <div
            className="contact-detail-timeline-container"
            role="tree"
            onKeyPress={noop}
            tabIndex="0"
            key={contactEntriesByDay.date}
            onClick={e => {
              if (
                (e.target.tagName === "BUTTON" || e.target.tagName === "DIV") &&
                e.target.getAttribute("data-identity")
              ) {
                const userRecord =
                  usersStatus?.dataMap[e.target.getAttribute("data-id")]
                onContactSelect &&
                  onContactSelect(
                    e.target.getAttribute("data-identity"),
                    userRecord,
                  )
              }
            }}>
            {/* date header */}
            <div className="row date-header-row">
              <div
                className="date-data-container header-field Sub-Heading-5"
                style={{
                  backgroundColor: "#fff2ca",
                }}>
                {contactEntriesByDay.date}
              </div>
              <div className="contact-data-container header-field Body-Text-5">
                Very Close Proximity
              </div>
              <div className="contact-data-container header-field Body-Text-5">
                Close Proximity
              </div>
              <div className="contact-data-container header-field Body-Text-5">
                In Proximity
              </div>
            </div>

            {/* contact data part */}
            <ContactDetailTimelineEntries
              contactEntries={contactEntriesByDay.contactEntries}
              usersStatus={usersStatus}
            />
          </div>
        )
      })}
    </DefaultPlaceholder>
  )
}

/**
 * @param {ContactStatus} contact
 */
const veryClose = contact => {
  return contact.distance <= 1.5
}

/**
 * @param {ContactStatus} contact
 */
const close = contact => {
  return contact.distance > 1.5 && contact.distance <= 3
}

/**
 * @param {ContactStatus} contact
 */
const far = contact => {
  return contact.distance > 3
}

/**
 * @param {{
 *   contactEntries: ContactEntry[],
 *   usersStatus: UsersStatus
 * }} param0
 */
const ContactDetailTimelineEntries = ({contactEntries, usersStatus}) => {
  return contactEntries.map(contactEntry => {
    return (
      <div
        className="contact-detail-time-card-container row"
        key={contactEntry.ReportTime}>
        <div className="date-data-container Sub-Heading-5 time-entry">
          <span>{timeFormatter.format(new Date(contactEntry.ReportTime))}</span>
        </div>
        <div className="contact-data-container Body-Text-5">
          {contactEntry.contactStatus.filter(veryClose).map(contactStatus => (
            <ContactStatusItem
              key={contactStatus.contact}
              contactStatus={contactStatus}
              userStatus={usersStatus.dataMap[contactStatus.userId]}
            />
          ))}
        </div>
        <div className="contact-data-container Body-Text-5">
          {contactEntry.contactStatus.filter(close).map(contactStatus => (
            <ContactStatusItem
              key={contactStatus.contact}
              contactStatus={contactStatus}
              userStatus={usersStatus.dataMap[contactStatus.userId]}
            />
          ))}
        </div>
        <div className="contact-data-container Body-Text-5">
          {contactEntry.contactStatus.filter(far).map(contactStatus => (
            <ContactStatusItem
              key={contactStatus.contact}
              contactStatus={contactStatus}
              userStatus={usersStatus.dataMap[contactStatus.userId]}
            />
          ))}
        </div>
      </div>
    )
  })
}

/**
 * @param {{
 *     contactStatus: ContactStatus,
 *     userStatus: UserRecord,
 * }} param0
 */
const ContactStatusItem = ({contactStatus, userStatus}) => {
  return (
    <div className="row contact-status-item" key={contactStatus.contact}>
      {/* status, if infection */}
      {isUserCovid19Positive(userStatus) ? (
        <Icon icon="ic-alert-red" size={30} style={{marginRight: 10}} />
      ) : (
        <div style={{width: 30, marginRight: 10}} />
      )}
      <div className="contact">
        <button
          className="contact-button"
          data-id={contactStatus.userId}
          data-identity={contactStatus.contact}>
          <div
            className="text-ellipsis"
            data-identity={contactStatus.contact}
            data-id={contactStatus.userId}>
            {userStatus.userInfo.name}
          </div>
        </button>
        <div className="text-ellipsis w-full">{contactStatus.contact}</div>
      </div>
    </div>
  )
}

export default ContactDetailTimeline

/**
 * @typedef {import('../domains/ct-contact').LastNDayContactTracks} LastNDayContactTracks
 * @typedef {import('../domains/ct-contact').ContactEntriesByDay} ContactEntriesByDay
 * @typedef {import('../domains/ct-contact').ContactEntry} ContactEntry
 * @typedef {import('../domains/ct-contact').ContactStatus} ContactStatus
 * @typedef {import('../domains/user').UsersStatus} UsersStatus
 * @typedef {import('../domains/user').UserRecord} UserRecord
 */
