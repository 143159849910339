import * as React from "react"

import {OutlinedButton} from "../components/base/button-outlined"

import {useRequestData} from "../services/ct"

/**
 * @param {{
 *     identity: string
 * }} param0
 */
const RequestDataButton = ({identity}) => {
  const {mutate: requestData, isLoading} = useRequestData({})

  return (
    <OutlinedButton
      width={155}
      loading={isLoading}
      onClick={() => {
        // console.log("request data", [identity])
        requestData({
          identifies: [identity],
        })
      }}>
      Request Data
    </OutlinedButton>
  )
}

export default RequestDataButton
