import {DateTime} from "luxon"

import {CategoryString} from "../../domains/category"

import authoredFetch from "../aws/amplify-fetch"
import {getUsersStatus} from "../smartworks-api/user"
import {generateUserId} from "../smartworks-api/auth"

import {
  DEFAULT_BASEURL,
  REQUEST_DATA_ENDPOINT,
  TRACK_ENDPOINT,
} from "./endpoints"

const dateFormatter = new Intl.DateTimeFormat("en", {
  month: "short",
  day: "2-digit",
})

/**
 *
 * @param {TrackQueryResponseData} a
 * @param {TrackQueryResponseData} b
 */
const sortByReportTimeDesc = (a, b) => {
  return b.ReportTime - a.ReportTime
}
/**
 * @typedef {object} GetLastNDaysTrackParams
 * @property {string} identify
 * @property {string} workspace
 * @property {number=} days
 */
/**
 * get the detail track of a user
 * @param {GetLastNDaysTrackParams} param0
 * @returns {Promise<LastNDayContactTracks>}
 */
export async function getLastNDaysTrackByIdentity({
  identify,
  workspace,
  days = 14,
}) {
  const lastNDay = DateTime.local()
    .minus({days: days})
    .startOf("day")
    .toMillis()

  /** @type {import('../fetch').FetchResponse<TrackQueryResponseData[]>} */
  let response
  try {
    response = await authoredFetch(TRACK_ENDPOINT, {
      baseURL: DEFAULT_BASEURL,
      method: "GET",
      params: {
        identify,
        startTime: lastNDay,
        appName: "contactracer",
      },
    })
  } catch (error) {
    // for 400 not found error, treat it as no data
    if (error?.response?.status === 400) {
      return {
        contactEntriesDays: [],
        usersStatus: {},
      }
    }
    throw error
  }

  const contactsMap = {}
  const contactEntriesByDayArr = []
  /** @type {ContactEntriesByDay} */
  let contactEntriesByDay

  //  group the contact response with date
  for (const contactResponse of response.data.sort(sortByReportTimeDesc)) {
    const contactResponseDate = dateFormatter.format(
      new Date(contactResponse.ReportTime),
    )

    // need to create new by day entry
    if (contactEntriesByDay?.date !== contactResponseDate) {
      if (contactEntriesByDay && contactEntriesByDay.contactEntries.length)
        contactEntriesByDayArr.push(contactEntriesByDay)
      contactEntriesByDay = {
        date: contactResponseDate,
        contactEntries: [],
      }
    }

    let contactStatus = contactResponse.Contacts.map((contact, index) => {
      if (!contactsMap[contact]) contactsMap[contact] = true
      return {
        userId: generateUserId({workspace, email: contact}),
        contact,
        distance: contactResponse.Distance[index],
        status: contactResponse.Status[index],
      }
    })

    contactEntriesByDay.contactEntries.push({
      ...contactResponse,
      contactStatus,
    })
  }
  if (contactEntriesByDay && contactEntriesByDay.contactEntries.length)
    contactEntriesByDayArr.push(contactEntriesByDay)

  const usersStatus = await getUsersStatus({
    users: Object.keys(contactsMap),
    category: CategoryString.workStatus,
  })

  return {
    contactEntriesDays: contactEntriesByDayArr,
    usersStatus,
  }
}

/**
 * @typedef {object} RequestDataParams
 * @property {string[]} identifies
 */

/**
 * send notification to user device to forceUpload
 * @param {RequestDataParams} param0
 * @returns {Promise<{success: number, fail: number}>}
 */
export async function requestData({identifies = []}) {
  const response = await authoredFetch(REQUEST_DATA_ENDPOINT, {
    baseURL: DEFAULT_BASEURL,
    method: "POST",
    data: {
      identifies,
      appName: "contactracer",
      data: {
        title: "upload",
        message: "contactracer://upload",
      },
    },
  })

  return response.data
}

/**
 * @typedef {import('../../domains/ct-contact').TrackQueryResponseData} TrackQueryResponseData
 * @typedef {import('../../domains/ct-contact').ContactEntriesByDay} ContactEntriesByDay
 * @typedef {import('../../domains/ct-contact').LastNDayContactTracks} LastNDayContactTracks
 */
